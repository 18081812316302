import React from "react";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import CrisisDirectoryListing from "../components/CrisisDirectoryListing/CrisisDirectoryListing";
import Footer from "../components/Footer/Footer";

const CrisisDirectory = () => {
  return (
    <Layout>
      <Header />
      <CrisisDirectoryListing />
      <Footer />
    </Layout>
  );
};

export default CrisisDirectory;
