/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import { Link } from "gatsby";
import IsrLabel from "../../../IsrLabel/IsrLabel";
import { getSrc } from "gatsby-plugin-image";
import IsrButton from "../../../IsrButton/IsrButton";
import Spacer from "../../../Spacer/Spacer";
import "./ProjectItem.scss";

const ProjectItem = ({ ProjectData, setFilters, isStatic }) => {
  const { title, date, image, crisis } = ProjectData;
  const uri = isStatic ? ProjectData.uri : `/crisis/${ProjectData.link}/`;
  const crisisStatus = isStatic
    ? crisis.crisisStatus
    : ProjectData.crisisStatus;

  const crisisHoverText = isStatic
    ? crisis.crisisStatusHoverText
    : ProjectData.crisisStatusHoverText;

  const imageUrl = getSrc(
    crisis?.headingSection?.backgroundImage?.localFile.childImageSharp
  );

  const crisisDeclarationDate = isStatic
    ? crisis.crisisDeclarationDate
    : ProjectData.crisisDeclarationDate;
  const crisisIncidentPeriod = isStatic
    ? crisis.crisisIncidentPeriod
    : ProjectData.crisisIncidentPeriod;

  const getCrisisStatusLabel = () => {
    if (crisisStatus)
      switch (crisisStatus) {
        case "Preparatory":
          return (
            <IsrLabel
              variant="hoverable"
              color="blue"
              size="md"
              hoverMessage={crisisHoverText ? crisisHoverText : ""}
            >
              Preparatory
            </IsrLabel>
          );
        case "Declared":
          return (
            <IsrLabel
              variant="hoverable"
              color="pink"
              size="md"
              hoverMessage={crisisHoverText ? crisisHoverText : ""}
            >
              Declared
            </IsrLabel>
          );
        case "Proposal submissions now closed":
          return (
            <IsrLabel
              variant="hoverable"
              color="grey"
              size="md"
              hoverMessage={crisisHoverText ? crisisHoverText : ""}
            >
              Proposal submissions now closed
            </IsrLabel>
          );

        default:
          return null;
      }
    else return null;
  };
  return (
    <article className="col-12 col-md-6 col-lg-6">
      <div className="crisis-article">
        <div className="article-wrapper">
          {title && (
            <Link to={uri} title={title}>
              <h3>{title}</h3>
            </Link>
          )}
          <Spacer size={20} mobileSize={10} />
          {getCrisisStatusLabel()}
          {imageUrl && (
            <div className="image-wrapper">
              <img src={imageUrl} alt={title} />
            </div>
          )}
          {image && (
            <div className="image-wrapper">
              <img src={image} alt={title} />
            </div>
          )}
          <Spacer size={30} mobileSize={20} />
          <div className="featured-article-actions">
            <div className="crisis-meta">
              <p>
                <strong>Declaration Date: </strong>{" "}
                {crisisDeclarationDate ? crisisDeclarationDate : "N/A"}
              </p>
              <p>
                <strong>Incident Period: </strong>{" "}
                {crisisIncidentPeriod ? crisisIncidentPeriod : "N/A"}
              </p>
            </div>
          </div>
          <div className="featured-article-actions">
            <div>
              <IsrButton variant="secondary" size="sm">
                <Link to={uri} title={title}>
                  Learn More
                </Link>
              </IsrButton>
            </div>
            <Link to={uri} title={title}>
              <IsrButton variant="arrow" size="sm" />
            </Link>
          </div>
        </div>
      </div>
    </article>

    // <article className="project-item">
    //   {title && (
    //     <Link to={uri} title={title}>
    //       <h3>{title}</h3>
    //     </Link>
    //   )}
    //   <div className="project-categories">
    //     {crisisName && (
    //       <span
    //         onClick={() =>
    //           setFilters({
    //             crisisName: { label: crisisName, value: crisisNameId },
    //           })
    //         }
    //       >
    //         <IsrLabel variant="secondary" size="md" color="grey">
    //           {crisisName}
    //         </IsrLabel>
    //       </span>
    //     )}
    //     {projectStatus && (
    //       <span
    //         onClick={() =>
    //           setFilters({
    //             projectStatus: { label: projectStatus, value: projectStatus },
    //           })
    //         }
    //       >
    //         <IsrLabel variant="secondary" size="md" color="grey">
    //           {projectStatus}
    //         </IsrLabel>
    //       </span>
    //     )}
    //     {researchCategories}
    //     {country && (
    //       <span
    //         onClick={() =>
    //           setFilters({
    //             country: { label: country, value: countryValue },
    //           })
    //         }
    //       >
    //         <IsrLabel variant="secondary" size="md" color="grey">
    //           {country}
    //         </IsrLabel>
    //       </span>
    //     )}
    //   </div>
    //   <div className="article-body">
    //     <div>
    //       <p className="author">{author}</p>
    //       {/* <p className="location">{location}</p> */}
    //       {date && <p className="date">{date}</p>}
    //     </div>
    //     <div>
    //       <p
    //         className="excerpt"
    //         // eslint-disable-next-line react/no-danger
    //         dangerouslySetInnerHTML={{
    //           __html: excerpt,
    //         }}
    //       />
    //     </div>
    //   </div>
    // </article>
  );
};

ProjectItem.propTypes = {
  ProjectData: PropTypes.shape({
    projectStatus: PropTypes.string,
    crisisName: PropTypes.shape({
      post_title: PropTypes.string,
      ID: PropTypes.number,
    }),
    country: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    link: PropTypes.string,
    title: PropTypes.string.isRequired,
    categories: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          uri: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
    uri: PropTypes.string,
    author: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        node: PropTypes.shape({ name: PropTypes.string.isRequired }),
      }),
    ]),
    date: PropTypes.string,
    tag: PropTypes.string,
    excerpt: PropTypes.string,
    slug: PropTypes.string,
    type: PropTypes.string,
    projectsCategories: PropTypes.oneOfType([
      PropTypes.shape({ nodes: PropTypes.array }),
      PropTypes.array,
    ]),
    projectSingle: PropTypes.shape({
      crisisName: PropTypes.shape({
        name: PropTypes.string,
        title: PropTypes.string,
        databaseId: PropTypes.number,
      }),
      projectStatus: PropTypes.string,
      country: PropTypes.array,
    }),
    pageExcerpt: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ pageExcerpt: PropTypes.string }),
    ]),
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  isStatic: PropTypes.bool.isRequired,
};

export default ProjectItem;
