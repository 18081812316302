import React, { useState, useEffect } from "react";
import axios from "axios";
import FilteringHeading from "./components/FilteringHeading/FilteringHeading";
import ProjectsListingSection from "./components/ProjectsListingSection/ProjectsListingSection";
import ProjectsListingPagination from "./components/ProjectsListingPagination/ProjectsListingPagination";
import useCrisesAll from "../../hooks/useCrises/useCrisesAll";
import "./CrisisDirectoryListing.scss";

const CrisisDirectoryListing = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState({
    totalResults: 0,
    projectOnPage: [],
  });

  const projectsPerPage = 10;
  let totalPages = 1;

  const [filteringOptions, setFilteringOptions] = useState({
    searchByName: "",
    crisesStatus: { value: false },
    sortBy: { value: "DESC" }, // newest first is default
  });

  const hasFiltered =
    filteringOptions.searchByName !== "" ||
    filteringOptions.crisesStatus.value !== false ||
    filteringOptions.sortBy.value !== "DESC";

  const setFilters = (filters) => {
    setFilteringOptions({
      ...filteringOptions,
      ...filters,
    });
  };

  const staticCrises = useCrisesAll();

  useEffect(() => {
    // if there is filtered something we call the endpoint
    if (hasFiltered) {
      const postConfig = {
        params: {
          ...(filteringOptions.searchByName && {
            search: filteringOptions.searchByName,
          }),
          ...(filteringOptions.crisesStatus.value && {
            crisis_status: filteringOptions.crisesStatus.value,
          }),
          ...(currentPage && { page: currentPage }),
          ...(projectsPerPage && { per_page: projectsPerPage }),
          ...(filteringOptions.sortBy.value && {
            sort: filteringOptions.sortBy.value,
          }),
        },
      };
      setIsLoading(true);

      axios
        .get(
          `${process.env.GATSBY_API_URL}/index.php/wp-json/crisis/v1/search`,
          postConfig
        )
        .then((response) => {
          if (response.data) {
            setProjects({
              projectOnPage: response.data.posts,
              totalResults: Math.ceil(
                response.data.totalCount / projectsPerPage
              ),
            });
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error); // eslint-disable-line no-console
          setIsLoading(false);
        });
    }
  }, [filteringOptions, currentPage]);

  // pagination
  const paginate = (array, pageSize, pageNumber) => {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };

  let projectsToShow = [];
  if (hasFiltered) {
    projectsToShow = projects.projectOnPage;
    totalPages = projects.totalResults;
  } else {
    totalPages = Math.ceil(staticCrises.length / projectsPerPage);
    projectsToShow = paginate(staticCrises, projectsPerPage, currentPage);
  }

  return (
    <div className="crisis-listing">
      <FilteringHeading
        setFilters={setFilters}
        filteringOptions={filteringOptions}
      />
      <ProjectsListingSection
        projects={projectsToShow}
        setFilters={setFilters}
        isLoading={isLoading}
        isStatic={!hasFiltered}
      />
      <ProjectsListingPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
      />
    </div>
  );
};

export default CrisisDirectoryListing;
