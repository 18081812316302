import { useStaticQuery, graphql } from "gatsby";

const useCrisesAll = () => {
  const data = useStaticQuery(graphql`
    {
      allWpCrisis(sort: { fields: date, order: DESC }) {
        nodes {
          id
          title
          uri
          date(formatString: "MMMM DD, YYYY")
          pageExcerpt {
            pageExcerpt
          }
          crisis {
            headingSection {
              dynamicPanel
              heading
              backgroundImage {
                localFile {
                  absolutePath
                  childImageSharp {
                    gatsbyImageData(formats: WEBP, height: 300, width: 1440)
                  }
                }
              }
            }
            crisisStatus
            crisisStatusHoverText
            crisisOverview
            crisisIncidentPeriod
            crisisDeclarationDate
          }
        }
      }
    }
  `);
  return data.allWpCrisis.nodes;
};

export default useCrisesAll;
