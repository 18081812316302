import React from "react";
import uniqueId from "lodash/uniqueId";
import PropTypes from "prop-types";
import ProjectItem from "../ProjectItem/ProjectItem";

const ProjectListingSection = ({
  projects,
  setFilters,
  isLoading,
  isStatic,
}) => {
  if (isLoading)
    return (
      <div className="loader">
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
        <p>Loading...</p>
      </div>
    );

  return (
    <div className="container">
      <div className="row">
        {projects instanceof Array && projects.length > 0 ? (
          projects.map((project) => (
            <ProjectItem
              key={uniqueId("project_")}
              ProjectData={project}
              setFilters={setFilters}
              isStatic={isStatic}
            />
          ))
        ) : (
          <h3>No results</h3>
        )}
      </div>
    </div>
  );
};

ProjectListingSection.propTypes = {
  projects: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
  setFilters: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isStatic: PropTypes.bool.isRequired,
};
export default ProjectListingSection;
